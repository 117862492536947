import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateLiquidateParamsSchema,
  PrivateLiquidateResponseSchema,
} from '../types/private.liquidate'

export default async function liquidate(
  params: PrivateLiquidateParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
) {
  return tryRequest<PrivateLiquidateParamsSchema, PrivateLiquidateResponseSchema>(
    '/private/liquidate',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
