import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateCancelQuoteParamsSchema,
  PrivateCancelQuoteResponseSchema,
} from '../types/private.cancel_quote'

export default async function fetchCancelQuote(
  params: PrivateCancelQuoteParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateCancelQuoteResponseSchema> {
  return tryRequest<PrivateCancelQuoteParamsSchema, PrivateCancelQuoteResponseSchema>(
    '/private/cancel_quote',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
