import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateCancelRfqParamsSchema,
  PrivateCancelRfqResponseSchema,
} from '../types/private.cancel_rfq'

export default async function fetchCancelRfq(
  params: PrivateCancelRfqParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateCancelRfqResponseSchema> {
  return tryRequest<PrivateCancelRfqParamsSchema, PrivateCancelRfqResponseSchema>(
    '/private/cancel_rfq',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
