import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import { PrivateSendRfqParamsSchema, PrivateSendRfqResponseSchema } from '../types/private.send_rfq'

export default async function fetchSendRfq(
  params: PrivateSendRfqParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateSendRfqResponseSchema> {
  return tryRequest<PrivateSendRfqParamsSchema, PrivateSendRfqResponseSchema>(
    '/private/send_rfq',
    {
      label: params.label,
      legs: params.legs,
      max_total_cost: params.max_total_cost,
      min_total_cost: params.min_total_cost,
      subaccount_id: params.subaccount_id,
    },
    {
      ...options,
      authHeaders,
    }
  )
}
