'use client'

import isServer from '@lyra/core/utils/isServer'
import emptyFunction from '@lyra/web/utils/emptyFunction'
import React, { createContext, memo, useCallback, useMemo, useState } from 'react'

type LocalStorage = Record<string, string>
type LocalStorageIsSet = Record<string, boolean>

export const LocalStorageContext = createContext<{
  localStorage: LocalStorage
  localStorageIsSet: LocalStorageIsSet
  setItem: (key: string, val: string | null) => void
}>({
  localStorage: {},
  localStorageIsSet: {},
  setItem: emptyFunction,
})

export const LocalStorageProvider = ({ children }: { children?: React.ReactNode }) => {
  const [localStorage, setLocalStorage] = useState<LocalStorage>({})
  const [localStorageIsSet, setLocalStorageIsSet] = useState<LocalStorageIsSet>({})

  const setItem = useCallback((key: string, val: string | null) => {
    if (isServer) {
      return
    }
    setLocalStorageIsSet((localStorageIsSet) => ({
      ...localStorageIsSet,
      [key]: true,
    }))
    if (val != null) {
      setLocalStorage((localStorage) => ({
        ...localStorage,
        [key]: val,
      }))
      window.localStorage.setItem(key, val)
    } else {
      setLocalStorage((localStorage) => {
        const localStorageCopy = Object.assign({}, localStorage)
        delete localStorageCopy[key]
        return localStorageCopy
      })
      window.localStorage.removeItem(key)
    }
  }, [])

  const value = useMemo(
    () => ({ localStorage, localStorageIsSet, setItem }),
    [localStorage, localStorageIsSet, setItem]
  )

  return <LocalStorageContext.Provider value={value}>{children}</LocalStorageContext.Provider>
}

export default memo(LocalStorageProvider)
