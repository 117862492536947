import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import { PrivateOrderParamsSchema, PrivateOrderResponseSchema } from '../types/private.order'

export default async function sendOrder(
  params: PrivateOrderParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateOrderResponseSchema> {
  return tryRequest<PrivateOrderParamsSchema, PrivateOrderResponseSchema>(
    '/private/order',
    params,
    { authHeaders, ...options }
  )
}
