import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateCancelAllParamsSchema,
  PrivateCancelAllResponseSchema,
} from '../types/private.cancel_all'

export default async function sendCancelAll(
  params: PrivateCancelAllParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateCancelAllResponseSchema> {
  return tryRequest<PrivateCancelAllParamsSchema, PrivateCancelAllResponseSchema>(
    '/private/cancel_all',
    params,
    { authHeaders, ...options }
  )
}
