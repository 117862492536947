import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import { PrivateReplaceParamsSchema, PrivateReplaceResponseSchema } from '../types/private.replace'

export default async function replace(
  params: PrivateReplaceParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateReplaceResponseSchema> {
  return tryRequest<PrivateReplaceParamsSchema, PrivateReplaceResponseSchema>(
    '/private/replace',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
