import isServer from '@lyra/core/utils/isServer'
import { useMemo } from 'react'

import { NAV_HEIGHT_DESKTOP, NAV_MOBILE_HEIGHT, SITE_WIDE_NOTICE_HEIGHT } from '../constants/layout'
import useLiveIncidents from './useLiveIncidents'
import useSiteWideAnnouncements from './useSiteWideAnnouncements'

export default function useNavHeight() {
  const { incidents } = useLiveIncidents()
  const { activeAnnouncement } = useSiteWideAnnouncements()

  const hasActiveNotice = useMemo(() => {
    return !isServer && (!!incidents?.[0] || !!activeAnnouncement)
  }, [incidents, activeAnnouncement])

  return {
    mobileHeight: NAV_MOBILE_HEIGHT + (hasActiveNotice ? SITE_WIDE_NOTICE_HEIGHT : 0),
    desktopHeight: NAV_HEIGHT_DESKTOP + (hasActiveNotice ? SITE_WIDE_NOTICE_HEIGHT : 0),
  }
}
