import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import { PrivateDepositParamsSchema, PrivateDepositResponseSchema } from '../types/private.deposit'

export default async function deposit(
  params: PrivateDepositParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
) {
  return tryRequest<PrivateDepositParamsSchema, PrivateDepositResponseSchema>(
    '/private/deposit',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
