import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateSendQuoteParamsSchema,
  PrivateSendQuoteResponseSchema,
} from '../types/private.send_quote'

export default async function fetchSendQuote(
  params: PrivateSendQuoteParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateSendQuoteResponseSchema> {
  return tryRequest<PrivateSendQuoteParamsSchema, PrivateSendQuoteResponseSchema>(
    '/private/send_quote',
    {
      ...params,
      legs: params.legs, // TODO: this seems redundant?
    },
    { authHeaders, ...options }
  )
}
