import tryRequest from './tryRequest'
import {
  PublicGetAssetsParamsSchema,
  PublicGetAssetsResponseSchema,
} from './types/public.get_assets'

export default async function fetchAssets(
  params: PublicGetAssetsParamsSchema
): Promise<PublicGetAssetsResponseSchema> {
  return tryRequest<PublicGetAssetsParamsSchema, PublicGetAssetsResponseSchema>(
    '/public/get_assets',
    params
  )
}
