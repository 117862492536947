import tryRequest, { RequestOptions } from './tryRequest'
import {
  PublicGetTransactionParamsSchema,
  PublicGetTransactionResponseSchema,
} from './types/public.get_transaction'

export default async function getTransactionStatus(
  params: PublicGetTransactionParamsSchema,
  options?: RequestOptions
): Promise<PublicGetTransactionResponseSchema> {
  return tryRequest<PublicGetTransactionParamsSchema, PublicGetTransactionResponseSchema>(
    '/public/get_transaction',
    params,
    options
  )
}
