'use client'

import isServer from '@lyra/core/utils/isServer'
import { LocalStorageContext } from '@lyra/web/providers/LocalStorageProvider'
import React, { useCallback, useContext, useMemo } from 'react'

export default function useLocalStorage<T extends string>(
  key: string
): [T, React.Dispatch<T | null>] {
  const { localStorage, localStorageIsSet, setItem } = useContext(LocalStorageContext)
  const initialItemValue = !isServer ? window.localStorage.getItem(key) : null

  const setKeyItem = useCallback((val: string | null) => setItem(key, val), [key, setItem])

  return useMemo(() => {
    const val = localStorage[key] ?? (!localStorageIsSet[key] ? initialItemValue : null)
    return [val as T, setKeyItem]
  }, [initialItemValue, key, localStorage, localStorageIsSet, setKeyItem])
}
