import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateWithdrawParamsSchema,
  PrivateWithdrawResponseSchema,
} from '../types/private.withdraw'

export default async function withdraw(
  params: PrivateWithdrawParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
) {
  return tryRequest<PrivateWithdrawParamsSchema, PrivateWithdrawResponseSchema>(
    '/private/withdraw',
    params,
    { ...options, authHeaders }
  )
}
