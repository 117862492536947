import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateTransferPositionParamsSchema,
  PrivateTransferPositionResponseSchema,
} from '../types/private.transfer_position'

export default async function transferPosition(
  params: PrivateTransferPositionParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions & { method: 'POST' }
): Promise<PrivateTransferPositionResponseSchema> {
  return tryRequest<PrivateTransferPositionParamsSchema, PrivateTransferPositionResponseSchema>(
    '/private/transfer_position',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
