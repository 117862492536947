import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateCancelTriggerOrderParamsSchema,
  PrivateCancelTriggerOrderResponseSchema,
} from '../types/private.cancel_trigger_order'

export default async function sendCancelTriggerOrder(
  params: PrivateCancelTriggerOrderParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateCancelTriggerOrderResponseSchema> {
  return tryRequest<PrivateCancelTriggerOrderParamsSchema, PrivateCancelTriggerOrderResponseSchema>(
    '/private/cancel_trigger_order',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
