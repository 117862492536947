import fetchLiveIncidents from '@lyra/core/api/fetchLiveIncidents'
import { IncidentResponseSchema } from '@lyra/core/api/types/public.get_live_incidents'
import { SECONDS_IN_MINUTE } from '@lyra/core/constants/time'
import { useCallback, useEffect, useMemo } from 'react'
import useSWR from 'swr'

import useLocalStorage from './local_storage/useLocalStorage'

const POLL_RATE_MS = SECONDS_IN_MINUTE * 5 * 1000

const getIncidentId = (incident: IncidentResponseSchema) =>
  `${incident.label}-${incident.creation_timestamp_sec}`

const fetcher = async () => {
  const res = await fetchLiveIncidents()

  return res.result.incidents
}

export default function useLiveIncidents() {
  const [_dismissedIncidents, _setDismissedIncidents] = useLocalStorage('DISMISSED_UPTIME_BANNERS')
  const dismissedIncidents = useMemo(
    () => (_dismissedIncidents ? JSON.parse(_dismissedIncidents) : {}),
    [_dismissedIncidents]
  )

  const dismissIncident = useCallback(
    (incident: IncidentResponseSchema) => {
      dismissedIncidents[getIncidentId(incident)] = true
      _setDismissedIncidents(JSON.stringify(dismissedIncidents))
    },
    [_setDismissedIncidents, dismissedIncidents]
  )

  const { data, isLoading } = useSWR('LiveIncidents', fetcher, {
    revalidateOnFocus: false,
    refreshInterval: POLL_RATE_MS,
  })

  const incidents = useMemo(
    () => (data ? data.filter((i) => !dismissedIncidents[getIncidentId(i)]) : []),
    [data, dismissedIncidents]
  )

  // Prune old dismissed incidents
  useEffect(() => {
    const allIncidents = data ?? []
    const incidentIds = allIncidents.map((i) => getIncidentId(i))
    const prunedDismissedIncidents = { ...dismissedIncidents }
    Object.keys(dismissedIncidents).forEach((incidentId) => {
      if (!incidentIds.includes(incidentId)) {
        delete prunedDismissedIncidents[incidentId]
      }
    })
    _setDismissedIncidents(JSON.stringify(prunedDismissedIncidents))
  }, [_setDismissedIncidents, dismissedIncidents, data])

  return {
    incidents,
    dismissIncident,
    isLoadingIncidents: isLoading,
  }
}
