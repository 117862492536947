import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateCreateSubaccountParamsSchema,
  PrivateCreateSubaccountResponseSchema,
} from '../types/private.create_subaccount'

export default async function createSubaccount(
  params: PrivateCreateSubaccountParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateCreateSubaccountResponseSchema> {
  return tryRequest<PrivateCreateSubaccountParamsSchema, PrivateCreateSubaccountResponseSchema>(
    '/private/create_subaccount',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
