import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateTransferErc20ParamsSchema,
  PrivateTransferErc20ResponseSchema,
} from '../types/private.transfer_erc20'

export default async function transferERC20(
  params: PrivateTransferErc20ParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions & { method: 'POST' }
): Promise<PrivateTransferErc20ResponseSchema> {
  return tryRequest<PrivateTransferErc20ParamsSchema, PrivateTransferErc20ResponseSchema>(
    '/private/transfer_erc20',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
