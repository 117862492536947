import getTransactionStatus from '@lyra/core/api/getTransactionStatus'
import { Hash } from 'viem'

import sleep from './sleep'

const POLLING_INTERVAL_MS = 500

// 'requested' | 'pending' | 'settled' | 'reverted' | 'timeout'
export default async function waitForTransaction(
  transactionId: string,
  timeoutMs = 20_000
): Promise<Hash> {
  if (timeoutMs <= 0) {
    throw new Error('Orderbook transaction timed out')
  }
  const {
    result: { status, transaction_hash },
  } = await getTransactionStatus({ transaction_id: transactionId })
  console.debug('status', transactionId, timeoutMs, status)
  if (status === 'pending' || status === 'requested') {
    await sleep(POLLING_INTERVAL_MS)
    return waitForTransaction(transactionId, timeoutMs - POLLING_INTERVAL_MS)
  } else if (status === 'reverted' || status === 'timeout' || !transaction_hash) {
    throw new Error(`Transaction ${status}: ` + (transaction_hash ?? 'No tx hash'))
  }
  return transaction_hash as Hash
}
