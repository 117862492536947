import { MARKET_ICON_SIZE } from '@lyra/web/constants/layout'
import { CurrencyId } from '@lyra/web/constants/tokens'
import Image from 'next/image'
import React from 'react'

type Props = {
  marketId: CurrencyId
  size?: number
}

export default function MarketIcon({
  marketId: tokenId,
  size = MARKET_ICON_SIZE,
}: Props): React.ReactNode {
  return (
    <Image
      width={size}
      height={size}
      alt={tokenId}
      src={`/images/tokens/${tokenId.toLowerCase()}.png`}
      style={{ borderRadius: 100000 }}
    />
  )
}
