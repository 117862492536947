import tryRequest from './tryRequest'
import {
  PublicGetLiveIncidentsParamsSchema,
  PublicGetLiveIncidentsResponseSchema,
} from './types/public.get_live_incidents'

export default async function fetchLiveIncidents(): Promise<PublicGetLiveIncidentsResponseSchema> {
  return tryRequest<PublicGetLiveIncidentsParamsSchema, PublicGetLiveIncidentsResponseSchema>(
    '/public/get_live_incidents',
    {}
  )
}
