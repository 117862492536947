import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateExecuteQuoteParamsSchema,
  PrivateExecuteQuoteResponseSchema,
} from '../types/private.execute_quote'

export default async function fetchExecuteRfq(
  params: PrivateExecuteQuoteParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateExecuteQuoteResponseSchema> {
  return tryRequest<PrivateExecuteQuoteParamsSchema, PrivateExecuteQuoteResponseSchema>(
    '/private/execute_quote',
    {
      label: params.label,
      legs: params.legs,
      subaccount_id: params.subaccount_id,
      rfq_id: params.rfq_id,
      // always buy on client side, legs dictate overall buy/sell
      direction: params.direction,
      max_fee: params.max_fee,
      nonce: params.nonce,
      signature_expiry_sec: params.signature_expiry_sec,
      signature: params.signature,
      signer: params.signer,
      quote_id: params.quote_id,
    },
    {
      ...options,
      authHeaders,
    }
  )
}
