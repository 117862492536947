import { useCallback, useEffect, useMemo, useState } from 'react'

import { SITE_WIDE_ANNOUNCEMENTS } from '../constants/announcements'
import useLocalStorage from './local_storage/useLocalStorage'

const DISMISSED_ANNOUNCEMENTS_KEY = 'derive_dismissed_announcements'

export default function useSiteWideAnnouncements() {
  const [isLoading, setIsLoading] = useState(true)
  const [_dismissedAnnouncements, _setDismissedAnnouncements] = useLocalStorage(
    DISMISSED_ANNOUNCEMENTS_KEY
  )
  const dismissedAnnouncements = useMemo(
    () => (_dismissedAnnouncements ? JSON.parse(_dismissedAnnouncements) : {}),
    [_dismissedAnnouncements]
  )

  useEffect(() => {
    if (_dismissedAnnouncements !== undefined) {
      setIsLoading(false)
    }
  }, [_dismissedAnnouncements])

  const activeAnnouncement = useMemo(() => {
    const now = Date.now()
    return SITE_WIDE_ANNOUNCEMENTS.find(
      (announcement) =>
        announcement.startTimestamp <= now &&
        announcement.endTimestamp >= now &&
        !dismissedAnnouncements[announcement.id]
    )
  }, [dismissedAnnouncements])

  const dismissAnnouncement = useCallback(
    (id: string) => {
      const newDismissed = { ...dismissedAnnouncements, [id]: true }
      _setDismissedAnnouncements(JSON.stringify(newDismissed))
    },
    [_setDismissedAnnouncements, dismissedAnnouncements]
  )

  return useMemo(
    () => ({
      activeAnnouncement,
      dismissAnnouncement,
      isLoadingAnnouncements: isLoading,
    }),
    [activeAnnouncement, dismissAnnouncement, isLoading]
  )
}
