import { LyraAuthHeaders } from '@lyra/core/constants/api'

import tryRequest, { RequestOptions } from '../tryRequest'
import { PrivateCancelParamsSchema, PrivateCancelResponseSchema } from '../types/private.cancel'

export default async function sendCancel(
  params: PrivateCancelParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateCancelResponseSchema> {
  return tryRequest<PrivateCancelParamsSchema, PrivateCancelResponseSchema>(
    '/private/cancel',
    params,
    { authHeaders, ...options }
  )
}
